import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { useGlobalState } from "../App";

export const Modal = ({ children = null, title, modal, onClose = null }) => {
  const [globalModal, setGlobalModal] = useGlobalState("modal");

  return (
    <Transition.Root show={globalModal === modal} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[999] overflow-y-auto"
        onClose={() => {
          setGlobalModal(null);
          if (onClose) onClose();
        }}
      >
        <div className="m-4 flex min-h-[calc(100%-4rem)] items-center justify-center text-center">
          <Dialog.Overlay className="fixed inset-0" />
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <div className="relative inline-block w-full select-none overflow-hidden rounded-xxl border-[max(0.5vh,0.25rem)] border-black bg-primary bg-opacity-90 p-4 text-left align-bottom transition-all sm:w-full sm:max-w-2xl sm:p-6 sm:align-middle">
            <div className="absolute top-0 right-0 block pt-4 pr-4">
              <button
                type="button"
                className="rounded-md text-slate-300 hover:text-white focus:outline-none"
                onClick={() => setGlobalModal(null)}
              >
                <XIcon className="h-8 w-8" />
              </button>
            </div>
            <div className="mx-4 mt-4 mb-8 text-center">
              <Dialog.Title as="h3" className="mx-2 mb-8 text-2xl font-bold leading-6 text-white md:text-3xl">
                {title}
              </Dialog.Title>

              <div className="pt-4">{children}</div>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
